@import url('https://fonts.googleapis.com/css?family=Josefin+Slab:600,600i,700|Yellowtail&display=swap');
@import "~@renaissancerentals/renaissance-component/lib/assets/App";


.glasses-background {
  background-image: url(./glasses-background.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
}

main {
  .heading {
    .emphasized {
      text-transform: uppercase;
    }
  }

  .section-property-location {
    h3 {
      color: $section-heading-color;
    }

    p {
      i {
        font-family: $main-font;
      }
    }

    a {
      color: $primary-color;
      cursor: pointer;
      text-decoration: underline;
    }

    .property--aside {
      color: $section-heading-color;
      font-size: .9rem;
      font-style: italic;
    }
  }
  .section-floorplan {
    background-color: $secondary-background-color;
  }
  .muncher-badge {
    background-color: $secondary-background-color;

    p {
      color: $primary-color;
    }

    .muncher-button {
      p {
        color: $primary-background-color;
      }
    }

    .selected {
      color: $primary-color;
    }
  }

  .section-card {
    color: $primary-color;

    .card-header {
      background-color: $primary-color;
    }

    .muncher-button--tertiary {
      background-color: $secondary-background-color;
      color: $primary-color;
    }
    i {
      font-family: $serif-font;
    }
  }
}
@import "src/assets/variables";
.logo {

  img {
    height: 1.5rem;
  }
  @media (min-width: $medium-size) {
    padding: 1rem;
    img {
      height: 2rem;
    }
  }
}